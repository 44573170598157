import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/**
 *  取得第一層(考試名稱與類科)
 */
export const GetBuyCourseExamAndCategory = () => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseExamAndCategory');

/**
 *  取得第二層(考試班別 by 類科)
 * @param {object} data { categoryId }
 */
export const GetBuyCourseCourseClass = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseCourseClass', data);

/**
 * 取得第三層(有效課程 by 班別)
 * @param {object} data { pageIndex, pageSize, data{categoryId} }
 */
export const GetBuyCourseList = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseList', data);

/**
 * 取得有效課程內容by課程id(所有前台要取得課程內容都可以使用)
 * @param {object} data { courseId }
 */
export const GetBuyCourseContent = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseContent', data);

/**
 * 取得有效課程內容by課程id(所有前台要取得課程內容都可以使用)
 * @param {object} data { courseId }
 */
export const GetBuyCourseContentByMember = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseContentByMember', data);

/**
 * 取觀看時間redis
 * @param {object} data { name, type }
 */
export const GetVideoTime = function GetVideoTime(data, showLoading = false) {
  baseAxios.defaults.showLoading = showLoading;
  return baseAxios.post('FrontEnd/BuyCourse/GetVideoTime', data);
};

/**
 * 存觀看時間redis
 * @param {object} data { name, time, action }
 */
export const SetVideoTime = function SetVideoTime(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/BuyCourse/SetVideoTime', data);
};

/**
 * 取可觀看得cookies
 * @param {object} data { name, time }
 */
export const GetVideoCookies = function GetVideoCookies(data, showLoading = false) {
  baseAxios.defaults.showLoading = showLoading;
  return baseAxios.post('FrontEnd/BuyCourse/GetVideoCookies', data);
};

/**
 * 課程查詢
 * @param {object} data { pageIndex, pageSize, data{searchContent} }
 */
export const GetBuyCourseSearch = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseSearch', data);

/**
 * 精選課程@param {object} data { courseType }
 */
export const GetBuyCourseSearchByWonderful = (data) => baseAxios.post('FrontEnd/BuyCourse/GetBuyCourseSearchByWonderful', data);

export const DownloadFileBase64 = (path) => baseAxios.post('FrontEnd/BuyCourse/DownloadFileBase64', { path });
