<template>
  <!-- 主要內容 -->
  <section class="buycourse pb-8 pt-md-6" v-if="IsReady">
    <div class="container">
      <div class="row">
        <!-- 側邊攔 -->
        <aside class="col-12 col-md-4 col-lg-3 d-none d-md-block">
          <ul class="cs-side" data-style="1" ref="csSide">
            <li
              v-for="item in ListData"
              :key="item.examId"
              class="side__item"
              @click="ToggleClass($event)"
            >
              <div class="side__title">
                <span class="mr-auto">{{ item.examName }}</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <ul class="side__box">
                <li v-for="itemCategory in item.categoryList" :key="itemCategory.categoryId">
                  <i class="fa fa-book"></i>
                  <a href="#" @click.prevent="ChangeCategory(item.examId, itemCategory.categoryId)">
                    {{ itemCategory.categoryName }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </aside>
        <!-- 主要內容 -->
        <div class="col-12 col-md-8 col-lg-9 pb-3 pb-md-8">
          <!-- 麵包屑 TODO 待補-->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb cs-breadcrumb bg-white align-items-center px-0">
              <li class="breadcrumb-item">
                <router-link to="/course">{{ GetExamName() }}</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a href="" @click.prevent="ClearRoomId()">{{ GetCategoryName() }}</a>
              </li>
            </ol>
          </nav>
          <template v-if="Args.roomId === 0">
            <article class=" p-3 p-md-6">
              <h3 class="cs-title my-3 my-md-6" data-style="1">
                <span class="" data-title="請選擇班別"></span>
              </h3>
              <ul class="row">
                <li
                  v-for="(item, index) in ClassRoomData"
                  :key="`roomid` + index"
                  class="col-12 col-md-6 col-lg-4 mb-4 mb-md-8"
                >
                  <a href="#" class="btn btn-course" @click.prevent="ChangeRoomId(item.roomId)">
                    {{ item.roomName }}
                  </a>
                </li>
              </ul>
            </article>
          </template>

          <template v-else>
            <!-- 下拉選單 -->
            <div class="dropdown cs-select mb-6" data-style="1">
              <button
                type="button"
                class="btn dropdown-toggle select__btn"
                id="dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-offset="10,20"
              >
                {{ GetDropdownName() }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                <div
                  class="dropdown-item select__option"
                  :class="{ active: Args.roomId === item.roomId }"
                  v-for="(item, index) in ClassRoomData"
                  :key="'dropdownRoom' + index"
                  @click="ChangeRoomId(item.roomId)"
                >
                  {{ item.roomName }}
                </div>
              </div>
            </div>
            <!-- 課程列表 -->
            <article class="row cs-card" data-style="4" v-if="PageInfo.data.length !== 0">
              <!-- 課程商品 -->
              <div
                class="col-12 col-md-6 col-lg-4"
                v-for="item in PageInfo.data"
                :key="'courseId' + item.courseId"
              >
                <div class="course__card card card-hover-focus mb-6 mb-md-8">
                  <router-link :to="`/course/classRoom/${item.courseId}`">
                    <div
                      class="course__img bg-outer"
                      :class="{ course__img__star: item.courseType === 2 }"
                    >
                      <div
                        class="bg-image"
                        :style="{
                          backgroundImage: 'url(' + item.mainImagePath + ')',
                        }"
                      ></div>
                    </div>
                    <div class="course__title">
                      <h3>{{ item.courseName }}</h3>
                    </div>
                    <div class="card-body course__body text-center">
                      <div class="course__price">原價 {{ item.courseOriPrice | toCurrency }}元</div>
                      <div class="course__sale d-block">
                        <i class="fa fa-star mr-1" aria-hidden="true"> </i
                        ><span>特價 {{ item.coursePrice | toCurrency }}元</span>
                      </div>
                    </div>
                  </router-link>
                  <a class="course__btn-cart" href="" @click.prevent="AddToCar(item.courseId)"
                    >加入購物車</a
                  >
                </div>
              </div>
            </article>
            <article class="row cs-card" data-style="4" v-else>
              <div class="error-bg mb-3 mb-md-8">
                <div>即將推出</div>
              </div>
            </article>
            <!-- 分頁 -->
            <Pagination
              @GetList="GetBuyCourseList"
              :page.sync="Pagination.pageIndex"
              :page-row="Pagination.pageSize"
              :total-count="PageInfo.count"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  GetBuyCourseExamAndCategory,
  GetBuyCourseCourseClass,
  GetBuyCourseList,
} from '@/api/BuyCourseApi';
import Pagination from '@/components/Pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      IsReady: false,
      Args: { examId: 0, categoryId: 0, roomId: 0 },
      ListData: [],
      ClassRoomData: [],
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {
          categoryId: 0,
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  // async activated() {
  // this.IsReady = false;
  // if (!this.$route.meta.isBack) {
  //   // 不是從指定頁面返回的話，進行初始化設定
  //   this.Args = { examId: 0, categoryId: 0, roomId: 0 };
  //   this.Pagination = {
  //     pageIndex: 1, // 預設從第幾頁開始
  //     pageSize: 6, // 預設每頁幾筆
  //     data: {
  //       categoryId: 0,
  //     },
  //   };
  //   const { examId, categoryId } = this.$route.params;
  //   this.Args.categoryId = Number.isNaN(parseInt(categoryId, 10)) ? 0 : parseInt(categoryId, 10);
  //   this.Args.examId = Number.isNaN(parseInt(examId, 10)) ? 0 : parseInt(examId, 10);
  // }
  // // 然後再針對頁面進行初始化資料存取
  // this.Init();
  // },
  async created() {
    const { examId, categoryId, roomId } = this.$route.params;
    this.Args.categoryId = Number.isNaN(parseInt(categoryId, 10)) ? 0 : parseInt(categoryId, 10);
    this.Args.examId = Number.isNaN(parseInt(examId, 10)) ? 0 : parseInt(examId, 10);
    this.Args.roomId = Number.isNaN(parseInt(roomId, 10)) ? 0 : parseInt(roomId, 10);
    this.Init();
    if (this.Args.roomId !== 0) this.GetBuyCourseList();
  },
  mounted() {},
  methods: {
    async Init() {
      await this.GetList();
      this.GetClassRoomList();
      this.IsReady = true;
    },
    ClearRoomId() {
      this.Args.roomId = 0;
    },
    GetExamName() {
      return this._.find(this.ListData, { examId: this.Args.examId }).examName;
    },
    GetCategoryName() {
      const category = this._.find(this.ListData, { examId: this.Args.examId }).categoryList;
      return this._.find(category, { categoryId: this.Args.categoryId }).categoryName;
    },
    GetDropdownName() {
      let roomName = '';
      if (this.ClassRoomData.length > 0) {
        roomName = this._.find(this.ClassRoomData, { roomId: this.Args.roomId }).roomName;
      }
      return roomName;
    },
    ToggleClass(event) {
      if (event.target.className !== 'side__box' && event.target.className !== '') event.currentTarget.classList.toggle('active');
    },
    ChangeRoomId(roomId) {
      this.Args.roomId = roomId;
      this.GetBuyCourseList();
    },
    ChangeCategory(examId, categoryId) {
      this.Args.examId = examId;
      this.Args.categoryId = categoryId;
      this.Args.roomId = 0;
      this.GetClassRoomList();
    },
    GetList() {
      return GetBuyCourseExamAndCategory().then((response) => {
        if (response.data.statusCode === 0) {
          this.ListData = response.data.data;
          if (this.Args.examId === 0) {
            this.Args.examId = this.ListData[0].examId;
            if (this.ListData[0].categoryList.length > 0) {
              this.Args.categoryId = this.ListData[0].categoryList[0].categoryId;
            }
          }
        }
      });
    },
    GetClassRoomList() {
      GetBuyCourseCourseClass({ CategoryId: this.Args.categoryId }).then((response) => {
        if (response.data.statusCode === 0) {
          this.ClassRoomData = response.data.data;
        }
      });
    },
    GetBuyCourseList() {
      this.Pagination.data.categoryId = this.Args.roomId;
      GetBuyCourseList(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    AddToCar(id) {
      this.$bus.$emit('addShoppingCart', id);
    },
  },
};
</script>

<style></style>
